import { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

//import components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import DropDown from "./components/DropDown";

//import style
import "./App.css";
//import page
import Home from "./pages";
import About from "./pages/about";
import Menu from "./pages/menu";
import Contact from "./pages/contact";

export default function App() {
	const [menuOpen, setMenuOpen] = useState(false);

	const toggle = () => {
		setMenuOpen(!menuOpen);
	};

	useEffect(() => {
		const hideMenu = () => {
			if (window.innerWidth > 768 && menuOpen) {
				setMenuOpen(false);
			}
		};

		window.addEventListener("resize", hideMenu);

		return () => {
			window.removeEventListener("resize", hideMenu);
		};
	});

	return (
		<>
			<Navbar toggle={toggle} />
			<DropDown menuOpen={menuOpen} toggle={toggle} />
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/アバウト" exact component={About} />
				<Route path="/メニュー" exact component={Menu} />
				<Route path="/コンタクト" exact component={Contact} />
			</Switch>
			<Footer />
		</>
	);
}
