import Hero from "../components/Hero";
import Content from "../components/Content";
import Chef from "../components/Chef";

export default function Home() {
	return (
		<div>
			<Hero />
			<Content />
			<Chef />
		</div>
	);
}
