export default function Content() {
	return (
		<>
			<h1 className="main-heading">メニュー</h1>
			<div className="grid md:grid-cols-2">
				<div className="menu-card">
					<img
						src="https://images.pexels.com/photos/5908232/pexels-photo-5908232.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
						alt="ramen-img"
						className="h-full rounded mb-20 shadow-2xl"
					/>
					<div className="center-content">
						<h2 className="sub-heading">ゆで卵ラーメン</h2>
						<p className="mb-2">美味しくて栄養価が高い</p>
						<span>500円</span>
					</div>
				</div>
				<div className="menu-card">
					<img
						src="https://images.pexels.com/photos/6646069/pexels-photo-6646069.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
						alt="ramen-img"
						className="h-full rounded mb-20 shadow-2xl"
					/>
					<div className="center-content">
						<h2 className="sub-heading">エビラーメン</h2>
						<p className="mb-2">サクサク、美味しく、栄養価が高い</p>
						<span>700円</span>
					</div>
				</div>
			</div>
		</>
	);
}
