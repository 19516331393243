export default function Chef() {
	return (
		<>
			<h1 className="main-heading">私たちのシェフ</h1>
			<div className="grid md:grid-cols-2">
				{/*chef 1*/}
				<div className="menu-card">
					<img
						src="https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2020/03/06/552350029edc49f69728c56ab2145163_Yoon+Mi+Wol+Yunke+Michelin+Guide+Tokyo.jpg"
						alt="ramen-img"
						className="h-full rounded mb-20 shadow-2xl"
					/>
					<div className="center-content">
						<h2 className="sub-heading">ミー・ウォル・ユーン</h2>
					</div>
				</div>
				{/*chef 2*/}
				<div className="menu-card">
					<img
						src="https://cdn.asiatatler.com/asiatatler/i/hk/2019/03/05111116-o-hilda-chan-vertical_article_1334x2000.jpg"
						alt="ramen-img"
						className="h-full rounded mb-20 shadow-2xl"
					/>
					<div className="center-content">
						<h2 className="sub-heading">ヒルダチャン</h2>
					</div>
				</div>
				{/*chef 3*/}
				<div className="menu-card">
					<img
						src="https://cdn.asiatatler.com/asiatatler/i/hk/2019/03/04102821-o-joanna-yuen_article_1333x2000.jpg"
						alt="ramen-img"
						className="h-full rounded mb-20 shadow-2xl"
					/>
					<div className="center-content">
						<h2 className="sub-heading">ジョアンナユエン</h2>
					</div>
				</div>
				{/*chef 4*/}
				<div className="menu-card">
					<img
						src="https://cdn.asiatatler.com/asiatatler/i/hk/2019/03/04102821-o-esther-sham_article_1333x2000.jpg"
						alt="ramen-img"
						className="h-full rounded mb-20 shadow-2xl"
					/>
					<div className="center-content">
						<h2 className="sub-heading">エスターシャム</h2>
					</div>
				</div>
			</div>
		</>
	);
}
