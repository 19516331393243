import { Link } from "react-router-dom";

export default function DropDown({ menuOpen, toggle }) {
	return (
		<div
			className={
				menuOpen
					? "grid grid-rows-4 text-center items-center bg-yellow-500"
					: "hidden"
			}
			onclick={toggle}
		>
			<Link className="p-4" to="/">
				ホーム
			</Link>
			<Link className="p-4" to="/メニュー">
				メニュー
			</Link>
			<Link className="p-4" to="/アバウト">
				アバウト
			</Link>
			<Link className="p-4" to="/コンタクト">
				コンタクト
			</Link>
		</div>
	);
}
